<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container min-w-full max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full h-full">
            <h1 class="mb-8 text-3xl text-center">
              Projets validés sans traitement et supprimés dans 15 jours
            </h1>

            <t-table :headers="getHeader" :data="paginatedProjects">
              <template v-slot:thead="props">
                <thead :class="props.theadClass">
                  <tr :class="props.trClass">
                    <template v-for="(item, index) in props.data" :class="[props.thClass]">
                      <th :key="index" :class="{ hidden: item.text == 'id' }">
                        {{ item.text }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </template>
              <template slot="row" slot-scope="props">
                <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']" class="text-left">
                  <td class="hidden">
                    {{ props.row.id }}
                  </td>
                  <!-- <td  v-if="roleName!='user'">
                            {{ props.row.refIntern }}
                          </td> -->
                  <td class="pl-2">
                    {{ props.row.nom }}
                  </td>
                  <td class="pl-2">
                    {{ timestamp(props.row.dateDepot) }}
                  </td>

                  <td class="text-center">
                    <router-link :to="`/projet/${props.row.id}`">
                      <svg height="32" viewBox="0 0 512 512" width="32" xmlns="http://www.w3.org/2000/svg">
                        <g id="Solid">
                          <path
                            d="m260.52 329.539a24 24 0 0 0 33.941 33.941l90.51-90.51a24 24 0 0 0 0-33.941l-90.51-90.509a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941l49.539 49.539h-262.059a24 24 0 0 0 -24 24 24 24 0 0 0 24 24h262.059z"
                          />
                          <path
                            d="m448 24h-224a40 40 0 0 0 -40 40v32a24 24 0 0 0 48 0v-24h208v368h-208v-24a24 24 0 0 0 -48 0v32a40 40 0 0 0 40 40h224a40 40 0 0 0 40-40v-384a40 40 0 0 0 -40-40z"
                          />
                        </g>
                      </svg>
                    </router-link>
                  </td>
                </tr>
              </template>
              <template slot="tfoot" slot-scope="{ tfootClass, trClass, tdClass, renderResponsive }">
                <tfoot :class="tfootClass">
                  <tr :class="trClass">
                    <td :class="tdClass" :colspan="renderResponsive ? 2 : 7">
                      <t-pagination
                        :hide-prev-next-controls="renderResponsive"
                        :total-items="projectData.length"
                        :per-page="10"
                        :class="{
                          'ml-auto': !renderResponsive,
                          'mx-auto': renderResponsive
                        }"
                        @change="getPage"
                      />
                    </td>
                  </tr>
                </tfoot>
              </template>
            </t-table>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { apolloProjectClient } from '../vue-apollo';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalListProjectsToDelete',
  components: {},
  props: [],
  data() {
    return {
      type_traitement: '0',
      projectData: [],
      lenUsers: 0,
      showmodal: false,
      numPage: 1,
      showModal: false,
      header: []
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    }),
    paginatedProjects() {
      if (this.projectData == null) {
        return [];
      }
      var slicedProjects = this.projectData.slice(10 * (this.numPage - 1), 10 * this.numPage - 1);
      return slicedProjects;
    },
    getHeader() {
      // if(this.roleName=='user'){
      //     return  ['id', 'Nom Projet', 'Date dépot' , 'Acceder']
      // }else {
      //     return  ['id','Ref interne', 'Nom Projet', 'Date dépot', 'Acceder']

      // }
      return ['id', 'Nom Projet', 'Date dépot', 'Acceder'];
    }
  },
  methods: {
    getPage(num) {
      console.log(num);
      this.numPage = num;
    },
    pad(n) {
      return n < 10 ? '0' + n.toString(10) : n.toString(10);
    },
    timestamp(dateString) {
      let d = new Date(dateString);
      var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      var time = [this.pad(d.getHours()), this.pad(d.getMinutes())].join(':');
      return [this.pad(d.getDate()), months[d.getMonth()], d.getFullYear()].join('/') + ' ' + time;
    },

    toggleModal: function () {
      this.fetchOptions();
      this.showModal = !this.showModal;
      this.numPage = 1;
    },
    async fetchOptions() {
      try {
        let { data: data } = await apolloProjectClient.query({
          query: gql`
            query {
              SearchProjectsByDate {
                id
                refIntern
                nom
                dateDepot
              }
            }
          `,

          fetchPolicy: 'network-only'
        });

        this.projectData = data.SearchProjectsByDate;
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    closeForme: function () {
      this.showModal = false;
    }
  }
};
</script>
