<template>
  <div class="flex items-center px-2 py-4 hover:bg-primary-light">
    <div class="flex flex-col items-center my-3">
      <!-- <Icon class="my-1 flex-shrink-0 w-8 h-8 text-primary-normal bg-gray-1"
              :name="icon"
              size="5"
              round
              :notify="!readStatus" /> -->
      <div @click="deleteNotification">
        <Icon
          class="my-1 flex-shrink-0 w-8 h-8 text-white bg-red-600"
          name="delete"
          size="5"
          round
          :notify="false"
          @click="deleteNotification"
        />
      </div>
    </div>

    <div class="flex flex-col items-start mx-3" @click="toggleModal">
      <p class="uppercase text-gray-1 text-sm">
        {{ title }}
      </p>
      <p class="content text-left text-black-1 text-base mt-2">
        {{ message }}
      </p>
      <p class="text-gray-1 text-xs mt-1">
        {{ convertDate }}
      </p>
    </div>
  </div>
</template>

<script>
import Icon from './Icon';
import gql from 'graphql-tag';

export default {
  name: 'Notification',
  components: { Icon },
  props: {
    id: { type: String, default: '' },
    message: { type: String, default: '' },
    dateNotification: { type: String, default: '' },
    readStatus: { type: Boolean, default: false },
    details: { type: String, default: '' },
    typeNotification: { type: Number, default: 0 },
    updateNotifications: { type: Function, default: null },
    showDetails: { type: Function, default: null }
  },
  data() {
    return {
      title: '',
      icon: ''
    };
  },
  computed: {
    convertDate() {
      let date = new Date(this.dateNotification);
      return date.toLocaleString();
    }
  },
  mounted() {
    switch (this.typeNotification) {
      case 0:
        this.title = 'Message';
        this.icon = 'comment';
        break;
      case 1:
        this.title = 'Application';
        this.icon = 'chat';
        break;
      case 2:
        this.title = 'Complaint';
        this.icon = 'bolt';
        break;
    }
  },
  methods: {
    deleteNotification: function () {
      let query = gql`
        mutation deleteNotification($id: Int!) {
          deleteNotification(id: $id) {
            success
          }
        }
      `;

      this.$apollo
        .mutate({
          mutation: query,
          client: 'apolloProjectClient',

          variables: {
            id: this.id
          }
        })
        .then((data) => {
          // Result
          if (data.data.deleteNotification) {
            if (data.data.deleteNotification.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.updateNotifications();
            } else {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: 'Opération a échoué !'
              });
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Erreur !'
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    toggleModal: function () {
      if (this.typeNotification == 0) {
        this.showDetails(this.details, this.id);
      }
    }
  }
};
</script>

<style scoped>
div:first-child {
  transition: all 0.25s linear;
}
.content {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
