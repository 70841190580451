<template>
  <div
    id="app"
    :class="[
      'min-h-screen',
      needsScrollbar ? 'overflow-auto' : '',
      `pg-${currentRouteName}`
    ]"
  >
    <Nav class="hidden md:block" :menu-list-item="menuListItems" :user="user" />
    <header class="bg-white flex justify-between px-2 lg:px-20 border-b border-gray-3 border-solid">
      <Nav class="md:hidden" mode="mobile" :menu-list-item="menuListItems" :user="user" />
      <a class="flex items-center" to="/">
        <span
          class="font-bold"
          style="letter-spacing: 0.1em; font-family: Roboto, sans-serif; fontsize: 30px; color: #2a3180"
        >RAPHAL</span>
      </a>
      <div v-if="isAuth" class="items-center" :class="[isAuth == true ? 'flex' : 'hidden']">
        <ModalListProjectsToDelete ref="modalListProjectsToDelete" />
        <Button
          class="mx-2 relative focus:outline-none text-red-500 hover:text-primary-light"
          :class="{ 'text-raphal-termine': newDeleteNotification }"
          round
          @click.native="openListProjectsToDelete"
        >
          <!-- <span class="absolute top-0 right-0 h-2 w-2 rounded-full bg-primary-normal"></span> -->
          <svg
            version="1.1"
            viewBox="0 0 24 24"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            height="35"
            width="35"
            class="fill-current"
          >
            <g>
              <g>
                <path
                  d="M21.2,16.5L14.6,4.7c-0.5-1-1.5-1.5-2.6-1.5S9.9,3.7,9.4,4.7L2.8,16.5c-0.5,0.9-0.5,2.1,0,3S4.3,21,5.4,21h13.2    c1.1,0,2-0.6,2.6-1.5S21.7,17.5,21.2,16.5z M19.5,18.5c-0.1,0.1-0.3,0.5-0.9,0.5H5.4c-0.5,0-0.8-0.3-0.9-0.5s-0.3-0.5,0-1    l6.6-11.9c0.3-0.5,0.7-0.5,0.9-0.5s0.6,0,0.9,0.5l6.6,11.9C19.7,18,19.5,18.4,19.5,18.5z"
                />
                <path d="M12,9c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3C13,9.4,12.6,9,12,9z" />
                <circle cx="12" cy="16" r="1" />
              </g>
            </g>
          </svg>
          <div
            class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900"
            :class="{ 'bg-raphal-termine': newDeleteNotification }"
          >
            {{ projectTodeleteCount }}
          </div>
        </Button>
        <Button
          class="mx-2 relative focus:outline-none text-gray-1 hover:text-primary-light"
          :class="{ 'text-raphal-termine': newNotification }"
          round
          @click.native="openSideBar"
        >
          <!-- <span class="absolute top-0 right-0 h-2 w-2 rounded-full bg-primary-normal"></span> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            height="35"
            width="35"
            class="fill-current"
          >
            <use xlink:href="#c-icon-bell" />
          </svg>
        </Button>
        <!-- <Button
          @click.native="openSideBar"
          class="mx-2 relative text-gray-1 hover:text-primary-normal"
          round
        >
          <span class="absolute top-0 right-0 h-2 w-2 rounded-full bg-primary-normal"></span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            height="35"
            width="35"
            class="fill-current"
          >
            <use xlink:href="#c-icon-chat" />
          </svg>
        </Button> -->
        <!-- <Button @click.native="openSideBar" class="mx-2" round>
         <avatar v-if="this.user" type="simple"
                  :name="this.user.username">
          </avatar>
        </Button> -->
        <t-dropdown>
          <div slot="trigger" slot-scope="{ mousedownHandler, focusHandler, blurHandler, keydownHandler, isShown }">
            <button
              id="user-menu"
              class="flex items-center pr-3 text-sm text-gray-700 transition duration-150 ease-in-out bg-white border border-raphal-valider rounded-md focus:outline-none focus:shadow-solid"
              :class="{ 'border-gray-300 bg-gray-300': isShown }"
              aria-label="User menu"
              aria-haspopup="true"
              @mousedown="mousedownHandler"
              @focus="focusHandler"
              @blur="blurHandler"
              @keydown="keydownHandler"
            >
              <svg
                class="w-6 h-6 m-1 mr-1 rounded-md text-white focus:text-gray-300"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469-68.382812 0-132.667969 26.628906-181.019531 74.980469-48.351563 48.351562-74.980469 112.636719-74.980469 181.019531 0 68.378906 26.628906 132.667969 74.980469 181.019531 48.351562 48.351563 112.636719 74.980469 181.019531 74.980469 68.378906 0 132.667969-26.628906 181.019531-74.980469 48.351563-48.351562 74.980469-112.640625 74.980469-181.019531 0-68.382812-26.628906-132.667969-74.980469-181.019531zm-308.679687 367.40625c10.707031-61.648438 64.128906-107.121094 127.660156-107.121094 63.535156 0 116.953125 45.472656 127.660156 107.121094-36.347656 24.972656-80.324218 39.613281-127.660156 39.613281s-91.3125-14.640625-127.660156-39.613281zm46.261718-218.519531c0-44.886719 36.515626-81.398438 81.398438-81.398438s81.398438 36.515625 81.398438 81.398438c0 44.882812-36.515626 81.398437-81.398438 81.398437s-81.398438-36.515625-81.398438-81.398437zm235.042969 197.710937c-8.074219-28.699219-24.109375-54.738281-46.585937-75.078125-13.789063-12.480469-29.484375-22.328125-46.359375-29.269531 30.5-19.894531 50.703125-54.3125 50.703125-93.363281 0-61.425782-49.976563-111.398438-111.402344-111.398438s-111.398438 49.972656-111.398438 111.398438c0 39.050781 20.203126 73.46875 50.699219 93.363281-16.871093 6.941406-32.570312 16.785156-46.359375 29.265625-22.472656 20.339844-38.511718 46.378906-46.585937 75.078125-44.472657-41.300781-72.355469-100.238281-72.355469-165.574219 0-124.617188 101.382812-226 226-226s226 101.382812 226 226c0 65.339844-27.882812 124.277344-72.355469 165.578125zm0 0"
                />
              </svg>
              Bienvenue, {{ user.username }}
            </button>
          </div>

          <div slot-scope="{ hide, blurHandler }">
            <div class="text-center p-6 border-b">
              <svg class="h-24 w-24 rounded-full mx-auto" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469-68.382812 0-132.667969 26.628906-181.019531 74.980469-48.351563 48.351562-74.980469 112.636719-74.980469 181.019531 0 68.378906 26.628906 132.667969 74.980469 181.019531 48.351562 48.351563 112.636719 74.980469 181.019531 74.980469 68.378906 0 132.667969-26.628906 181.019531-74.980469 48.351563-48.351562 74.980469-112.640625 74.980469-181.019531 0-68.382812-26.628906-132.667969-74.980469-181.019531zm-308.679687 367.40625c10.707031-61.648438 64.128906-107.121094 127.660156-107.121094 63.535156 0 116.953125 45.472656 127.660156 107.121094-36.347656 24.972656-80.324218 39.613281-127.660156 39.613281s-91.3125-14.640625-127.660156-39.613281zm46.261718-218.519531c0-44.886719 36.515626-81.398438 81.398438-81.398438s81.398438 36.515625 81.398438 81.398438c0 44.882812-36.515626 81.398437-81.398438 81.398437s-81.398438-36.515625-81.398438-81.398437zm235.042969 197.710937c-8.074219-28.699219-24.109375-54.738281-46.585937-75.078125-13.789063-12.480469-29.484375-22.328125-46.359375-29.269531 30.5-19.894531 50.703125-54.3125 50.703125-93.363281 0-61.425782-49.976563-111.398438-111.402344-111.398438s-111.398438 49.972656-111.398438 111.398438c0 39.050781 20.203126 73.46875 50.699219 93.363281-16.871093 6.941406-32.570312 16.785156-46.359375 29.265625-22.472656 20.339844-38.511718 46.378906-46.585937 75.078125-44.472657-41.300781-72.355469-100.238281-72.355469-165.574219 0-124.617188 101.382812-226 226-226s226 101.382812 226 226c0 65.339844-27.882812 124.277344-72.355469 165.578125zm0 0"
                />
              </svg>
              <p class="pt-2 text-lg font-semibold">
                {{ user.firstName }} {{ user.lastName }}
              </p>
              <p class="text-sm text-gray-600">
                {{ user.email }}
              </p>
              <p
                v-if="role == 'manager' || (role == 'supervisor' && user.useJetons == true)"
                class="text-sm text-gray-600"
              >
                <span class="font-semibold">Jetons restants : </span>{{ user.jetonsRestants }}
              </p>

              <div class="mt-5">
                <a
                  href="#"
                  class="border rounded-full py-2 px-4 text-xs font-semibold text-gray-700"
                  @click="showEditUserForm"
                >
                  Gérer mon compte
                </a>
              </div>
            </div>
            <button
              class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              role="menuitem"
              @blur="blurHandler"
              @click.stop="disconnect"
            >
              Se déconnecter
            </button>

            <button
              class="block w-full px-4 py-2 text-sm leading-5 text-red-500 transition duration-150 ease-in-out border-t hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              @click="hide"
            >
              Fermer
            </button>
          </div>
        </t-dropdown>
      </div>
      <div v-if="!isAuth && currentRouteName != 'login'" class="flex items-center">
        <router-link
          class="m-1 mr-1 flex items-center pr-3 text-sm text-gray-700 transition duration-150 ease-in-out bg-white border border-raphal-valider rounded-md focus:outline-none focus:shadow-solid"
          :to="`/login`"
        >
          <svg
            class="w-6 h-6 m-1 mr-1 rounded-md text-white focus:text-gray-300"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469-68.382812 0-132.667969 26.628906-181.019531 74.980469-48.351563 48.351562-74.980469 112.636719-74.980469 181.019531 0 68.378906 26.628906 132.667969 74.980469 181.019531 48.351562 48.351563 112.636719 74.980469 181.019531 74.980469 68.378906 0 132.667969-26.628906 181.019531-74.980469 48.351563-48.351562 74.980469-112.640625 74.980469-181.019531 0-68.382812-26.628906-132.667969-74.980469-181.019531zm-308.679687 367.40625c10.707031-61.648438 64.128906-107.121094 127.660156-107.121094 63.535156 0 116.953125 45.472656 127.660156 107.121094-36.347656 24.972656-80.324218 39.613281-127.660156 39.613281s-91.3125-14.640625-127.660156-39.613281zm46.261718-218.519531c0-44.886719 36.515626-81.398438 81.398438-81.398438s81.398438 36.515625 81.398438 81.398438c0 44.882812-36.515626 81.398437-81.398438 81.398437s-81.398438-36.515625-81.398438-81.398437zm235.042969 197.710937c-8.074219-28.699219-24.109375-54.738281-46.585937-75.078125-13.789063-12.480469-29.484375-22.328125-46.359375-29.269531 30.5-19.894531 50.703125-54.3125 50.703125-93.363281 0-61.425782-49.976563-111.398438-111.402344-111.398438s-111.398438 49.972656-111.398438 111.398438c0 39.050781 20.203126 73.46875 50.699219 93.363281-16.871093 6.941406-32.570312 16.785156-46.359375 29.265625-22.472656 20.339844-38.511718 46.378906-46.585937 75.078125-44.472657-41.300781-72.355469-100.238281-72.355469-165.574219 0-124.617188 101.382812-226 226-226s226 101.382812 226 226c0 65.339844-27.882812 124.277344-72.355469 165.578125zm0 0"
            />
          </svg>
          SE CONNECTER
        </router-link>
      </div>
    </header>
    <router-view class="min-h-full" />
    <SideBar
      v-if="isAuth"
      style="z-index: 1100"
      :is-visible="isOpenSideBar"
      :toggle-notification-details="toggleModal"
    />
    <modalProfile ref="modalformeUser" :refresh-user="refreshUser" show-modal-sync="showmodal" />
    <modalNotifications ref="modalNotificationDetails" show-modal-sync="showmodal" />
  </div>
</template>

<script>
import Nav from './components/Nav';
import Button from './components/Button';
// import Avatar from "./components/Avatar"
import SideBar from './components/SideBar';
import modalProfile from './components/ModalProfile.vue';
import { mapGetters, mapActions } from 'vuex';
import modalNotifications from './components/ModalNotifications.vue';
import ModalListProjectsToDelete from './components/ModalListProjectsToDelete.vue';

import { apolloProjectClient } from './vue-apollo';
import gql from 'graphql-tag';

export default {
  name: 'App',
  components: {
    Nav,
    Button,
    SideBar,
    modalProfile,
    modalNotifications,
    ModalListProjectsToDelete
    // Avatar
  },
  data() {
    return {
      showmodal: false,
      newNotification: false,
      projectTodeleteCount: 0,
      menuListItems: [
        {
          id: 0,
          icon: '#c-icon-home',
          title: 'Accueil',
          url: '/accueil',
          roles: ['user', 'admin', 'manager', 'supervisor'],
          selected: false
        },
        {
          id: 5,
          icon: '#c-icon-bookmark',
          title: 'Suivi',
          url: '/bookmark',
          roles: ['user', 'admin', 'manager', 'supervisor'],
          cls: 'raphal-bookmark',
          selected: false
        },
        {
          id: 8,
          icon: '#c-icon-analytics',
          title: 'Statistiques',
          url: '/statistiques',
          roles: ['user', 'admin', 'manager', 'supervisor'],
          selected: false
        },
        {
          id: 7,
          icon: '#c-icon-users',
          title: 'Gestion',
          url: '/gestion',
          roles: ['admin', 'manager'],
          selected: false
        },
        {
          id: 9,
          icon: '#c-icon-device',
          title: 'Matériel',
          url: '/materiel',
          roles: ['admin'],
          selected: false
        },
        {
          id: 10,
          icon: '#c-icon-settings',
          title: 'Gestion projets',
          url: '/projects',
          roles: ['admin'],
          selected: false
        }
        /* {
          id: 10,
          icon: '#c-icon-settings',
          title: 'Reupload',
          url: '/reupload',
          roles: ['admin'],
          selected: false
        } */
      ],
      isOpenSideBar: false,
      needsScrollbar: this.isAuth == true && ['admin', 'projet'].indexOf(this.currentRouteName) < 0
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      role: 'Auth/roleUser'
    }),
    currentRouteName() {
      return this.$route.name;
    },
    newDeleteNotification() {
      if (this.projectTodeleteCount > 0) {
        return false;
      }
      return true;
    }
  },
  async created() {
    await this.$store.dispatch('Auth/getAuthUser');
    // await this.$store.dispatch("Auth/initiatRefresToken");
  },
  mounted() {
    this.getProjectTodeleteCount();
  },
  methods: {
    ...mapActions({
      logoutUser: 'Auth/logoutUser'
    }),
    openSideBar() {
      this.isOpenSideBar = !this.isOpenSideBar;
      this.newNotification = false;
    },
    openListProjectsToDelete() {
      this.$refs.modalListProjectsToDelete.toggleModal();
    },
    async disconnect() {
      await this.logoutUser();
    },
    refreshUser(firstName, lastName) {
      this.user.firstName = firstName;
      this.user.lastName = lastName;
    },
    showEditUserForm() {
      this.$refs.modalformeUser.toggleModal(this.user);
    },
    toggleModal: function (details, id) {
      this.$refs.modalNotificationDetails.toggleModal(details, id);
    },
    async getProjectTodeleteCount() {
      try {
        let { data: data } = await apolloProjectClient.query({
          query: gql`
            query {
              SearchProjectsByDate {
                nom
              }
            }
          `,
          fetchPolicy: 'network-only'
        });

        this.projectTodeleteCount = data.SearchProjectsByDate.length;
      } catch (err) {
        this.projectTodeleteCount = 0;
      }
    }
  }
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: grid;
  background-color: #f4f6fc;
  grid-template-columns: 100%;
  grid-template-rows: 70px 0.99fr;
  grid-template-areas:
    'head'
    'main';
}
#app.pg-gestion {
  grid-template-rows: 100px 1fr;
}
#app > header {
  grid-area: head;
}
main {
  grid-area: main;
}

@media (min-width: 768px) {
  #app {
    grid-template-columns: auto 1fr;
    grid-template-rows: 65px 0.99fr;
    grid-template-areas:
      'nav head'
      'nav main';
  }

  #app > header {
    grid-area: head;
  }
}

@media (min-width: 768px) {
  #app.pg-projet {
    height: 100%;
  }
}
</style>
