<template>
  <button type="button" :to="url" :alt="alt" :class="classes">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: { type: String, default: '' },
    rounded: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    url: { type: String, default: '' },
    alt: { type: String, default: '' }
  },
  data() {
    return {
      buttonClass: ''
    };
  },
  computed: {
    classes() {
      return (this.round === true ? 'rounded-full p-0' : '') + (this.rounded === true ? ' rounded' : '');
    }
  }
};
</script>

<style scoped>
button {
  transition: all 0.25s ease;
}
</style>
