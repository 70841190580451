import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createProvider } from './vue-apollo';
import VS2 from 'vue-script2';
import Vue2Crumbs from 'vue-2-crumbs';
import './assets/css/tailwind.css';
import VueTailwind from 'vue-tailwind';
import settings from './vue-tailwind';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import VueTilt from 'vue-tilt.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import raphalUtils from './utils';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

window.Toast = Toast;
window.Swal = Swal;
Vue.prototype.$potree = window.Potree;

Vue.config.productionTip = false;
// eslint-disable-next-line no-use-before-define
// var $ = window.jQuery;

Vue.use(VueTailwind, settings);
Vue.use(VS2);
Vue.use(VueTilt);
Vue.use(Vue2Crumbs);
Vue.use(raphalUtils);
store.dispatch('Auth/getAuthUser');

// eslint-disable-next-line
window.app = new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App)
}).$mount('#app');
