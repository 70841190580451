<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container min-w-full max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-gray-200 px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Infos Notification
            </h1>
            <div v-if="notificationDetails.apn" class="overflow-y-scroll h-96">
              <collapse :selected="false">
                <div slot="collapse-header">
                  Informations Appareil
                </div>
                <div slot="collapse-body">
                  <table class="w-full whitespace-nowrap">
                    <tbody>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Nombre Images</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.apn.nb_images }}</p>
                        </td>
                      </tr>

                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Prise de Vue</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.apn.image_date }}</p>
                        </td>
                      </tr>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Model Appareil</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.apn.model }}</p>
                        </td>
                      </tr>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Largeur Image</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.apn.image_width }}</p>
                        </td>
                      </tr>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Hauteur Image</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.apn.image_height }}</p>
                        </td>
                      </tr>

                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Images Flous</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.apn.nb_blur }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </collapse>
              <collapse :selected="false">
                <div slot="collapse-header">
                  Informations Balises
                </div>
                <div slot="collapse-body">
                  <table class="w-full whitespace-nowrap">
                    <tbody>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Nombre de Balises</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.balise.number }}</p>
                        </td>
                      </tr>

                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Indicateur Balise</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.balise.indicateur_balises }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </collapse>
              <collapse :selected="false">
                <div slot="collapse-header">
                  Photogrammetrie
                </div>
                <div slot="collapse-body">
                  <table class="w-full whitespace-nowrap">
                    <tbody>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Nombre Images alignées</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.photogrammetry.nb_align }}</p>
                        </td>
                      </tr>

                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>Indicateur 3D</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.photogrammetry.indicateur_3d }}</p>
                        </td>
                      </tr>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>mean_error_scale_bar</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.photogrammetry.mean_error_scale_bar.toFixed(3) }}</p>
                        </td>
                      </tr>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>std_error_scale_bar</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.photogrammetry.std_error_scale_bar.toFixed(3) }}</p>
                        </td>
                      </tr>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>max_error_scale_bar</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.photogrammetry.max_error_scale_bar.toFixed(3) }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </collapse>
            </div>
            <div v-else class="overflow-y-scroll h-52">
              <collapse :selected="true">
                <div slot="collapse-header">
                  Erreurs
                </div>
                <div slot="collapse-body">
                  <table class="w-full whitespace-nowrap">
                    <tbody>
                      <tr tabindex="0" class="focus:outline-none text-sm leading-none text-black-1 h-8 bg-gray-3">
                        <td class="w-1/2">
                          <p>message</p>
                        </td>
                        <td class="pl-16">
                          <p>{{ notificationDetails.error }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </collapse>
            </div>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { apolloProjectClient } from '../vue-apollo';
import { mapGetters } from 'vuex';
import Collapse from 'vue-collapse';

export default {
  name: 'ModalListProjects',
  components: {
    Collapse
  },
  props: [],
  data() {
    return {
      notificationDetails: '',
      showModal: false,
      idNotification: null
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    })
  },
  methods: {
    calculateDateFin: function (dateString) {
      console.log(dateString.split('T')[0]);
      let date = new Date(dateString);

      return date.toLocaleString();
    },
    toggleModal: function (details, id) {
      this.showModal = !this.showModal;
      this.notificationDetails = JSON.parse(details);
      this.id = id;
      this.markReadNotification();
    },
    async markReadNotification(q) {
      try {
        let { data: data } = await apolloProjectClient.query({
          query: gql`
            query ($searchQyery: String!) {
              SearchProjectsByTreatement(searchQyery: $searchQyery) {
                id
                nom
                dateDepot
                nbAlignedImages
                nbTotalImages
                ErreurMoyEchelle
              }
            }
          `,
          variables: {
            searchQyery: q
          }
        });

        this.projectData = data.SearchProjectsByTreatement;
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    cleanForme: function () {
      this.type_traitement = null;
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    affectToProject: function () {
      let query = gql`
        mutation affectUserToProject($idProject: Int!, $idUser: Int!) {
          affectUserToProject(idProject: $idProject, idUser: $idUser) {
            success
          }
        }
      `;

      this.$apollo
        .mutate({
          mutation: query,
          client: 'apolloProjectClient',

          variables: {
            idUser: this.userRow.id,
            idProject: this.projects
          }
        })
        .then((data) => {
          // Result
          if (data.data.affectUserToProject) {
            if (data.data.affectUserToProject.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.showModal = false;
              this.cleanForme();
            } else {
              let listErrors = 'Erreur\n';
              let dictErrors = [
                'email',
                'username',
                'password1',
                'password2',
                'firstName',
                'lastName',
                'nonFieldErrors'
              ];
              for (let error of dictErrors) {
                if (data.data.affectUserToProject.errors[error]) {
                  for (let err of data.data.register.errors[error]) {
                    listErrors += err.message + '\n';
                  }
                }
              }
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: listErrors
              });
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Erreur !'
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    }
  }
};
</script>
