<template>
  <div class="relative w-80 rounded-md px-6 py-4 bg-gray-200 border border-raphal-valider shadow-lg">
    <form action method="post" @submit.prevent="login">
      <div class="mb-4">
        <label for="username">Nom utilisateur</label>
        <t-input v-model="user.username" name="username" type="text" placeholder="Nom utilisateur" />
      </div>
      <div class="mb-6">
        <label for="password">Mot de passe</label>
        <t-input v-model="user.password" name="password" type="password" placeholder="******************" />
        <p class="text-right">
          <router-link class="text-right text-sm font-sans text-blue-500" :to="`/forgot`">
            Mot de passe oublié ?
          </router-link>
        </p>
      </div>
      <div class="flex w-full items-center justify-items-center justify-center">
        <button style="background: #37a8df" class="p-2 border rounded-md text-white focus:outline-none" type="submit">
          SE CONNECTER
        </button>
        <!-- <a
          class="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker"
        >Mot de passe oublié ?</a> -->
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    ...mapActions({
      loginUser: 'Auth/loginUser'
    }),
    async login() {
      await this.loginUser(this.user);
      // this.$apollo
      //   .mutate({
      //     // Query
      //     mutation: gql`
      //       mutation loginAuth($username: String!, $password: String!) {
      //         tokenAuth(username: $username, password: $password) {
      //           success
      //           errors
      //           unarchiving
      //           token
      //           refreshToken
      //           unarchiving
      //           user {
      //             id
      //             username
      //           }
      //         }
      //       }
      //     `,
      //     // Parameters
      //     variables: {
      //       username: this.username,
      //       password: this.password
      //     }
      //   })
      //   .then(data => {
      //     // Result
      //     console.log(data);
      //     onLogin(
      //       this.$apollo.provider.defaultClient,
      //       data.data.tokenAuth.token
      //     );
      //     this.$router.push("/dashboard");
      //   })
      //   .catch(error => {
      //     // Error
      //     console.error(error);
      //     // We restore the initial user input
      //   });
    }
  }
};
</script>
