<template>
  <nav class="c-nav bg-white" :class="isAuthenticated">
    <div class="c-nav__header">
      <button :class="{ 'is-logged': isAuth }" @click="isClosed = !isClosed">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="24" width="24">
          <use xlink:href="#c-icon-menu" />
        </svg>
      </button>
    </div>
    <div class="c-nav__menu flex flex-col justify-between" :class="[mode === 'mobile' ? 'is-mobile' : '']">
      <ul>
        <li v-for="item in menuListItem" :key="item.id" class="c-nav__menuitem">
          <router-link
            v-if="item.roles.includes(role)"
            :to="item.url"
            class="flex py-4 px-6 text-gray-1 hover:border-l-3 hover:border-solid hover:border-raphal-valider hover:bg-gray-1 hover:text-raphal-valider"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              height="24"
              width="24"
              class="fill-current"
              :class="[item.icon == '#c-icon-users' ? 'padding-icon' : '', item.cls]"
            >
              <use v-bind="{ 'xlink:href': item.icon }" />
            </svg>
            <span class="ml-3">{{ item.title }}</span>
          </router-link>
        </li>
      </ul>
      <a
        class="p-5 bg-raphal-termine text-lg font-bold text-center text-white w-full cursor-pointer md:hidden"
        @click="isClosed = !isClosed"
      >Fermer</a>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Nav',
  components: {},
  props: {
    mode: { type: String, default: '' },
    menuListItem: { type: Array, default: null }
  },
  data () {
    return {
      isClosed: true,
      islogged: false
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      role: 'Auth/roleUser'
    }),
    isAuthenticated() {
      if (this.isAuth == true) {
        return { 'is-closed': this.isClosed, 'is-logged': this.isAuth };
      }
      return { 'is-closed': this.isClosed };
    }
  }
};
</script>

<style>
.c-nav {
  grid-area: nav;
  width: 0px;
  transition: width 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}
.c-nav.is-closed {
  width: 80px !important;
}
.c-nav.is-logged {
  width: 215px;
}
.c-nav__header.is-logged {
  visibility: block;
}

.c-nav__header {
  padding: 30px;
  display: flex;
  justify-content: left;
}

.c-nav__user {
  height: 100px;
  padding: 30px;
}

.c-nav__menu.is-mobile {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 10000;
  transition:
    opacity 0.15s ease,
    transform 0.3s ease;
  opacity: 1;
  transform: translateX(0);
}
.c-nav.is-closed .c-nav__menu.is-mobile {
  opacity: 0;
  transform: translateX(-100%);
}
.c-nav__menuitem a {
  width: 100%;
  transition:
    border 0.1s linear,
    width 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.c-nav__menuitem a svg {
  width: 24px;
  height: 24px;
}
.c-nav__menuitem a svg.raphal-bookmark {
  width: 16px;
  height: 16px;
  margin: 5px 0 0 5px;
}
.c-nav .c-nav__menuitem a span {
  transition: visibility 0.1s 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.c-nav.is-closed .c-nav__menuitem a span {
  visibility: hidden;
  transition: visibility 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Actve state */
.c-nav__menuitem a.is-active {
  @apply border-l-3 border-solid bg-raphal-dhar text-raphal-valider border-raphal-valider;
}
.padding-icon {
  padding-left: 4px;
}
@media (min-width: 768px) {
  .c-nav__menuitem a {
    width: 215px;
  }
}
</style>
