import { REGISTER_USER, AUTHENTICATE_USER, RESHRESH_TOKEN, DELETE_TOKEN, DELETE_REFRESH_TOKEN } from '../../gql';
import Vue from 'vue';
import { apolloClient } from '../../vue-apollo';
// import router from '../../router';
import gql from 'graphql-tag';

const state = {
  user: {},
  authStatus: false,
  auth: localStorage.getItem('isAuth') || null,
  role: null,
  rtk: false,
  teria: false
};

const getters = {
  user: (state) => state.user,
  isAuth: (state) => state.auth,
  authStatus: (state) => state.authStatus,
  roleUser: (state) => state.role,
  isRtk: (state) => state.rtk,
  isTeria: (state) => state.teria
};

const actions = {
  async loginUser({ dispatch }, userData) {
    try {
      let {
        data: { authCookies }
      } = await apolloClient.mutate({
        mutation: AUTHENTICATE_USER,
        variables: {
          username: userData.username,
          password: userData.password
        }
      });
      // console.log(tokenAuth);
      dispatch('setAuthUserData', authCookies);
      Vue.prototype.$raphalUtils.goto('/accueil');
    } catch (err) {
      // eslint-disable-next-line
      Toast.fire({
        icon: 'error',
        title: 'Login ou mot de passe incorrect !'
      });
    }
  },
  async registerUser({ dispatch }, userData) {
    let {
      data: { registerUser }
    } = await apolloClient.mutate({
      mutation: REGISTER_USER,
      variables: {
        username: userData.username,
        password: userData.password
      }
    });
    dispatch('setAuthUserData', registerUser);
  },
  async setAuthUserData({ dispatch }, payload) {
    if (payload != null) {
      dispatch('getAuthUser');
      // eslint-disable-next-line
      Toast.fire({
        icon: 'success',
        title: 'Vous êtes connecté !'
      });
    } else {
      // eslint-disable-next-line
      Toast.fire({
        icon: 'error',
        title: 'Login ou mot de passe incorrect !'
      });
    }
  },
  async getAuthUser({ commit, dispatch }) {
    try {
      let {
        data: { me }
      } = await apolloClient.query({
        query: gql`
          query AUTH_USER {
            me {
              id
              username
              firstName
              lastName
              jetonsRestants
              useJetons
              usePgoc
              email
              role {
                name
              }
              rtk
              teria
            }
          }
        `,
        fetchPolicy: 'network-only'
      });
      // console.log(me);
      if (me == null) {
        dispatch('refreshToken');
      } else {
        commit('LOGIN_USER', {
          user: me
        });
      }
    } catch (err) {
      console.log(err);
      dispatch('logoutUser');
    }
  },
  async initiatRefresToken({ dispatch }) {
    setInterval(
      function () {
        dispatch('refreshToken');
        console.log('refreshToken');
      }.bind(this),
      5 * 60 * 1000
    );
  },
  async logoutUser({ commit, state }) {
    if (state.auth == true) {
      await apolloClient.mutate({
        mutation: DELETE_REFRESH_TOKEN
      });

      let {
        data: { deleteTokenCookie }
      } = await apolloClient.mutate({
        mutation: DELETE_TOKEN
      });

      if (deleteTokenCookie.deleted) {
        // eslint-disable-next-line
        Toast.fire({
          icon: 'success',
          title: 'Vous êtes déconnecté !'
        });
      }
      Vue.prototype.$raphalUtils.goto('/login');
    }
    commit('LOGOUT_USER');
    state.auth = false;
    localStorage.setItem('isAuth', false);
  },
  async refreshToken({ dispatch }) {
    try {
      let {
        data: { refreshToken }
      } = await apolloClient.mutate({
        mutation: RESHRESH_TOKEN
      });
      console.log(refreshToken);
      if (refreshToken != null) {
        // commit("SET_TOKEN", refreshToken);
        // localStorage.setItem("apollo-token", refreshToken.token);
        // localStorage.setItem("apollo-refreshtoken", refreshToken.refreshToken);
        // dispatch('getAuthUser')
        apolloClient
          .query({
            query: gql`
              query AUTH_USER {
                me {
                  id
                  username
                  firstName
                  lastName
                  email
                  totalJetons
                  usedJetons
                  role {
                    name
                  }
                  rtk
                  teria
                }
              }
            `
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      } else {
        dispatch('logoutUser').then(() => {
          Vue.prototype.$raphalUtils.goto('/');
        }).catch(() => {});
      }
    } catch (err) {
      console.log(err.message);
      // eslint-disable-next-line
      dispatch('logoutUser').then(() => {
        Vue.prototype.$raphalUtils.goto('/');
      }).catch(() => {});
      // Toast.fire({
      //   icon: "error",
      //   title: err.nonFieldErrors[0].message
      // });
    }
  }
};

const mutations = {
  LOGIN_USER(state, payload) {
    state.user = payload.user;
    state.authStatus = true;
    state.auth = true;
    localStorage.setItem('isAuth', true);
    state.role = payload.user.role.name;
    state.rtk = payload.user.rtk;
    state.teria = payload.user.teria;
  },
  SET_TOKEN(state) {
    state.auth = true;
  },
  LOGOUT_USER(state) {
    state.user = {};
    state.authStatus = false;
    state.auth = false;
    state.rtk = false;
    state.teria = false;
    localStorage.setItem('isAuth', false);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
