<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Gérer le Profile
            </h1>

            <input
              v-model="firstname"
              type="text"
              class="block border border-grey-light w-full p-3 rounded mb-4"
              name="Nom"
              placeholder="Nom"
            >
            <input
              v-model="lastname"
              type="text"
              class="block border border-grey-light w-full p-3 rounded mb-4"
              name="prenom"
              placeholder="Prénom"
            >

            <label class="flex items-center mb-4">
              <t-checkbox v-model="changepassword" name="chks_password" />
              <span class="ml-2 text-sm">Changer le mot de passe ?</span>
            </label>
            <div :class="[changepassword ? 'block' : 'hidden']">
              <input
                v-model="password"
                type="password"
                class="flex-1 block border border-grey-light w-full p-3 rounded mb-4 mr-4"
                name="password"
                placeholder="Ancien Mot de passe"
              >

              <input
                v-model="password1"
                type="password"
                class="flex-1 block border border-grey-light w-full p-3 rounded mb-4 mr-4"
                name="password"
                placeholder="Mot de passe"
              >
              <input
                v-model="password2"
                type="password"
                class="border border-grey-light w-full p-3 rounded mb-4"
                name="confirm_password"
                placeholder="Confirmer Mot de passe"
              >
            </div>

            <button
              class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="UpdateProfile"
            >
              Modifier
            </button>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

export default {
  name: 'Modalprofile',
  components: {},
  props: {
    refreshUser: { type: Function, default: null }
  },
  data() {
    return {
      showModal: false,
      userRow: null,
      firstname: null,
      lastname: null,
      username: null,
      email: null,
      password: null,
      password1: null,
      password2: null,
      changepassword: false
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    })
  },
  methods: {
    calculateDateFin: function (dateString) {
      console.log(dateString.split('T')[0]);
      let date = new Date(dateString);

      return date.toLocaleString();
    },
    toggleModal: function (userRow) {
      this.showModal = !this.showModal;
      this.userRow = userRow;
      if (this.userRow != null) {
        this.firstname = this.userRow.firstName;
        this.lastname = this.userRow.lastName;
        this.username = this.userRow.username;
        this.email = this.userRow.email;
        this.role = this.userRow.role.name;
      }
    },
    cleanForme: function () {
      this.userRow = null;
      this.firstname = null;
      this.lastname = null;
      this.password = null;
      this.password1 = null;
      this.password2 = null;
      this.changepassword = false;
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    UpdateProfile: function () {
      let query = gql`
        mutation updateProfile(
          $password: String
          $password1: String
          $password2: String
          $firstname: String!
          $lastname: String!
          $changePassword: Boolean!
        ) {
          updateProfile(
            changePassword: $changePassword
            firstname: $firstname
            lastname: $lastname
            password: $password
            password1: $password1
            password2: $password2
          ) {
            success
            errors
          }
        }
      `;

      this.$apollo
        .mutate({
          mutation: query,
          variables: {
            firstname: this.firstname,
            lastname: this.lastname,
            password: this.password,
            password1: this.password1,
            password2: this.password2,
            changePassword: this.changepassword
          }
        })
        .then((data) => {
          // Result
          if (data.data.updateProfile) {
            if (data.data.updateProfile.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.showModal = false;
              this.refreshUser(this.firstname, this.lastname);
              this.cleanForme();
            } else {
              let listErrors = 'Erreur\n';
              listErrors = data.data.updateProfile.errors[0].message;

              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: listErrors
              });
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Erreur !'
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    }
  }
};
</script>
