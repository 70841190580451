import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import gql from 'graphql-tag';
import { setContext } from 'apollo-link-context';
import store from './store';
// import { InMemoryCache } from "apollo-cache-inmemory"
// import { setContext } from 'apollo-link-context'

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';
const authLink = setContext(async (_, { headers }) => {
  if (middlware.apolloClient) {
    try {
      let {
        data: { verifyToken }
      } = await middlware.apolloClient.mutate({
        mutation: gql`
          mutation {
            verifyToken {
              payload
            }
          }
        `
      });
      if (verifyToken) {
        console.log(verifyToken);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('%cError on cache reset (login)', 'color: orange;', e.message);
      try {
        let {
          data: { refreshToken }
        } = await middlware.apolloClient.mutate({
          mutation: gql`
            mutation {
              refreshToken {
                token
                refreshToken
                payload
              }
            }
          `
        });
        console.log(refreshToken);
      } catch (e) {
        console.log('%cError on cache reset (login)', 'color: orange;', e.message);
        store.dispatch('Auth/logoutUser');
      }
    }
  }

  return {
    headers: {
      ...headers
    }
  };
});

// if using https, use wss too
const wsScheme = window.location.protocol == "https:" ? "wss" : "ws";

// projects endpoint
const clientProjets = {
  httpEndpoint: window.location.origin + '/api/api_raphal/projects/',
  wsEndpoint: wsScheme + '://' + window.location.host + '/api/api_raphal/projects/'
};

// auth endpoint
const clientAuth = {
  httpEndpoint: window.location.origin + '/api/api_raphal/auth/',
  wsEndpoint: null
};

// Config

// const authLink = setContext(async (_, { headers }) => {
//   // Use your async token function here:
//   const token = JSON.parse(localStorage.getItem('apollo-token'))

//   // Return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization:  token ? `JWT ${token}` : null
//     }
//   }
// })

// Config
const defaultOptions = {
  // // You can use `https` for secure connection (recommended in production)
  // httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions

  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  httpLinkOptions: {
    credentials: 'include'
  }
  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink
  // link:  concat(authLink, httpLink),

  // Override default cache
  // cache: myCache
  // cache: new InMemoryCache({
  //   addTypename: true
  // }),
  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...
  // getAuth: () => {
  //   // get the authentication token from local storage if it exists
  //   const token = localStorage.getItem(AUTH_TOKEN)
  //   // return the headers to the context so httpLink can read them
  //   if (token) {
  //     return 'JWT ' + token
  //   } else {
  //     return ''
  //   }
  // }
  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

const middlware = createApolloClient({
  ...defaultOptions,
  ...clientAuth
});
export const { apolloClient } = createApolloClient({
  ...defaultOptions,
  ...clientAuth,
  link: authLink
});

const projectClient = createApolloClient({
  ...defaultOptions,
  ...clientProjets,
  link: authLink
});

export const apolloProjectClient = projectClient.apolloClient;
// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...clientAuth,
    ...options,
    link: authLink
  });

  const projectClient = createApolloClient({
    ...defaultOptions,
    ...clientProjets,
    link: authLink
  });
  const apolloProjectClient = projectClient.apolloClient;
  const middlwareClient = middlware.apolloClient;
  apolloClient.wsClient = null;
  apolloProjectClient.wsClient = null;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      apolloClient,
      apolloProjectClient,
      middlwareClient
    },
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'network'
      }
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      );
    }
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
