<template>
  <article
    class="fixed overflow-y-scroll w-72 h-screen bg-white border-l border-gray-3 border-solid shadow-2l"
    :class="{ 'is-open': isVisible }"
  >
    <header class="px-3 py-2 border-b border-gray-3 border-solid">
      <Button
        class="w-32 text-base flex flex-row border border-black-2 items-center text-black-2 rounded-md hover:bg-raphal-traiter hover:text-white"
        rounded
        @click="clearNotifications"
      >
        <Icon class="my-1 flex-shrink-0 w-6 h-6 mr-2" name="delete" size="5" round />
        Effacer tout
      </Button>
    </header>

    <ul>
      <li
        v-for="(notification, index) in allUsernotifications"
        :key="index"
        class="border-b border-gray-3 border-solid"
      >
        <Notification
          :id="notification.id"
          class="cursor-pointer"
          :type-notification="notification.typeNotification"
          :message="notification.message"
          :details="notification.details"
          :date-notification="notification.dateNotification"
          :read-status="notification.readStatus"
          :update-notifications="updateNotifications"
          :show-details="toggleModal"
        />
      </li>
    </ul>
  </article>
</template>

<script>
// import Button from "./Button";
import Notification from './Notification';
import gql from 'graphql-tag';
import Icon from './Icon';

export default {
  name: 'SideBar',
  components: {
    // Button,
    Notification,
    Icon
  },
  props: {
    isVisible: { type: Boolean, default: false },
    toggleNotificationDetails: { type: Function, default: null }
  },
  data() {
    return {
      notifications: []
    };
  },
  methods: {
    clearNotifications() {
      let query = gql`
        mutation {
          deleteBulkNotification {
            success
          }
        }
      `;
      this.$apollo
        .mutate({
          mutation: query,
          client: 'apolloProjectClient'
        })
        .then((data) => {
          // Result
          if (data.data.deleteBulkNotification) {
            if (data.data.deleteBulkNotification.success == true) {
              this.$apollo.queries.allUsernotifications.refetch();
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
            } else {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: 'Erreur !'
              });
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Erreur !'
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    updateNotifications() {
      this.$apollo.queries.allUsernotifications.refetch();
    },
    toggleModal: function (details, id) {
      // this.$refs.modalNotificationDetails.toggleModal(details,id);
      this.toggleNotificationDetails(details, id);
    }
  },
  apollo: {
    $client: 'apolloProjectClient',
    allUsernotifications: {
      query: gql`
        query {
          allUsernotifications {
            id
            message
            dateNotification
            readStatus
            details
            typeNotification
          }
        }
      `,
      fetchPolicy: 'no-cache'
    },
    $subscribe: {
      onUserNotification: {
        query: gql`
          subscription {
            onUserNotification {
              userNotification {
                id
                message
                dateNotification
                readStatus
                details
                typeNotification
              }
            }
          }
        `,
        result() {
          this.$apollo.queries.allUsernotifications.refetch();
          this.$parent.newNotification = true;
        }
      }
    }
  }
};
</script>

<style scoped>
article {
  top: 80px;
  right: -18rem;
  opacity: 0;
  transition:
    transform 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.3s linear;
}
article.is-open {
  transform: translateX(-18rem);
  opacity: 1;
}
</style>
