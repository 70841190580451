import gql from 'graphql-tag';

export const REGISTER_USER = gql`
  mutation REGISTER_USER(
    $username: String!
    $password: String!
    $firstName: String!
    $email: String!
    $lastName: String!
  ) {
    registerUser(
      newUser: { username: $username, password: $password, firstName: $firstName, lastName: $lastName, email: $email }
    ) {
      user {
        id
        username
        avatarImage
        email
        lastName
        firstName
      }
      token
    }
  }
`;

export const AUTHENTICATE_USER = gql`
  mutation AUTHENTICATE_USER($username: String!, $password: String!) {
    authCookies(username: $username, password: $password) {
      payload
    }
  }
`;

export const RESHRESH_TOKEN = gql`
  mutation {
    refreshToken {
      payload
    }
  }
`;

export const REVOKE_TOKEN = gql`
  mutation REVOKE_TOKEN($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      success
    }
  }
`;

export const DELETE_TOKEN = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
  }
`;
export const DELETE_REFRESH_TOKEN = gql`
  mutation {
    deleteRefreshTokenCookie {
      deleted
    }
  }
`;
